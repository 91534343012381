<template>
  <div class="w-full mt-28">
    <the-top-bar
      :icon="'fas fa-stream'"
      :title="'Promocije Proizvoda'"
    ></the-top-bar>
    <section class="pl-12 pb-12">
      <h2 role="heading" class="text-xl font-semibold text-gray-800 mt-5">
        Dodatne Informacije
      </h2>
      <small
        v-if="this.$store.getters.get_promotionProducts.length == 0"
        class="text-gray-400"
        >Dodaj proizvode za promociji po ID broju</small
      >
      <small
        v-if="this.$store.getters.get_promotionProducts.length > 0"
        class="text-gray-400"
        >Trenutna aktivna promocija.</small
      >
      <div
        class="mt-4 flex items-center"
        v-if="this.$store.getters.get_promotionProducts.length == 0"
      >
        <div class="flex flex-col">
          <label class="mb-3 text-sm leading-none text-gray-800"
            >ID proizvoda</label
          >
          <input
            type="name"
            tabindex="0"
            aria-label="idProduct"
            class="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
            placeholder="ID Proizvoda"
            v-model="productId"
          />
        </div>
        <div class="ml-8">
          <button
            role="button"
            aria-label="Next step"
            class="flex items-center justify-center py-4 px-7 focus:outline-none bg-white border border-yellow rounded border-gray-400 my-7 md:mt-14 hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
            @click="addProduct"
          >
            <span class="text-sm font-medium text-center text-yellow capitalize"
              >Dodaj</span
            >
          </button>
        </div>
      </div>
      <!-- Active promotion -->
      <div
        v-if="this.$store.getters.get_promotionProducts.length > 0"
        class="transition-all opacity-1"
      >
        <h3>Aktivna Promocija</h3>
        <div
          class="grid md:grid-cols-2 lg:grid-cols-4 justify-items-between mt-8 gap-y-8 lg:gap-y-0 gap-x-8"
        >
          <product
            v-for="(product, index) in this.$store.getters
              .get_promotionProducts"
            :key="index"
            :id="product.id"
            :image="product.images[0].url"
            :model="product.model"
            :title="product.title"
            :price="product.price"
            :actionPrice="product.actionPrice"
          ></product>
        </div>
      </div>

      <div
        v-if="
          this.trackProduct.length > 0 &&
          this.$store.getters.get_promotionProducts.length == 0
        "
        class="transition-all opacity-1"
      >
        <h3>Proizvodi na promociji</h3>
        <div
          class="grid md:grid-cols-2 lg:grid-cols-4 justify-items-between mt-8 gap-y-8 lg:gap-y-0 gap-x-8"
        >
          <product
            v-for="(product, index) in trackProduct"
            :key="index"
            :id="product.id"
            :image="product.images[product.images.length - 1].url"
            :model="product.model"
            :title="product.title"
            :price="product.price"
            :actionPrice="product.actionPrice"
            :index="index"
          ></product>
        </div>
      </div>

      <button
        v-if="this.$store.getters.get_promotionProducts.length === 0"
        role="button"
        aria-label="Next step"
        class="flex items-center justify-center py-4 px-7 focus:outline-none bg-white border border-yellow rounded my-7 md:mt-14 hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
        @click="activatePromotion"
      >
        <span class="text-sm font-medium text-center text-yellow capitalize"
          >Aktiviraj Promociju</span
        >
      </button>
      <button
        v-if="this.$store.getters.get_promotionProducts.length > 0"
        role="button"
        aria-label="Next step"
        class="flex items-center justify-center py-4 px-7 focus:outline-none bg-white border border-yellow rounded my-7 md:mt-14 hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
        @click="deletePromotion"
      >
        <span class="text-sm font-medium text-center text-yellow capitalize"
          >Obrisi Promociju</span
        >
      </button>
    </section>
    <info-modal
      :message="this.message"
      v-show="$store.getters.get_infoModalOn"
    ></info-modal>
  </div>
</template>

<script>
import TheTopBar from "./TheTopBar.vue";
import InfoModal from "../../modals/InfoModal.vue";
import Product from "./PromotionProduct.vue";
export default {
  components: {
    TheTopBar,
    InfoModal,
    Product,
  },
  data() {
    return {
      productId: null,
      previewProducts: [],
      message: "",
    };
  },
  computed: {
    trackProduct() {
      return this.$store.getters.get_previewProduct;
    },
  },
  mouted() {
    this.$store.dispatch("getPromotion");
  },
  methods: {
    addProduct() {
      if (this.productId === "" || this.productId === null) {
        this.$store.commit("SET_INFO_MODAL", true);
        this.message = "Popuni ID polje!";
        return;
      }
      let filterProduct = this.$store.getters.get_allProducts.filter(
        (product) => product.id == this.productId
      );
      console.log(filterProduct);
      if (filterProduct.length == 0) {
        this.$store.commit("SET_INFO_MODAL", true);
        this.message = "Ovaj proizvod nije pronadjen!";
        return;
      }

      this.$store.commit("SET_PREVIEW_PRODUCTS", filterProduct);
      this.$store.commit("SET_PRODUCT_FOR_PROMOTION", this.productId);
      // this.productId = "";
    },
    async activatePromotion() {
      if (this.$store.getters.get_productForPromotion.length == 0) {
        this.$store.commit("SET_INFO_MODAL", true);
        this.message = "Morate prvo dodati proivode.";
        return;
      }
      await this.$store.dispatch("addPromotion", {
        products: this.$store.getters.get_productForPromotion,
      });

      this.$store.commit("RESET_PREVIEW");
    },
    async deletePromotion() {
      await this.$store.dispatch(
        "deletePromotion",
        this.$store.getters.get_promotionId
      );
      await this.$store.dispatch("getPromotionProducts");
      this.$store.commit("RESET_PROMOTION_STATUS", undefined);
      this.$store.commit("RESET_PREVIEW_PRODUCTS", []);
    },
  },
};
</script>

<style lang="scss"></style>
