<template>
  <div
    class="prod flex items-start flex-col py-4 px-6 transition-all relative border border-gray-200"
  >
    <div class="relative flex justify-center items-center">
      <!-- <router-link :to="{ name: 'ProductPage', params: { category, id } }"> -->
      <img
        :src="$store.getters.get_API + '/images/' + image"
        :alt="model"
        class="w-full h-auto cursor-pointer"
      />
      <!-- </router-link> -->
      <button
        v-if="this.$store.getters.get_promotionId == undefined"
        @click="removeProduct(index)"
        class="absolute top-4 right-4 flex justify-center items-center p-3.5 bg-gray-100 rounded-full transition-all transform hover:scale-125 hover:text-yellow"
      >
        <i class="far fa-trash-alt text-xl text-red-400"></i>
      </button>
    </div>
    <div class="flex flex-col items-start jusitfy-start mt-3 space-y-3">
      <div>
        <!-- <router-link
          :to="{ name: 'ProductPage', params: { category, id } }"
          class="cursor-pointer"
        > -->
        <p
          class="text-lg font-medium capitalize leading-4 text-gray-800 transition-all hover:text-yellow h-10"
        >
          {{ title }}
        </p>
        <p class="text-lg font-light leading-4 text-gray-800">{{ model }}</p>
        <!-- </router-link> -->
      </div>
      <div class="relative pb-8">
        <p class="text-lg my-2 leading-4 text-gray-600">
          <span v-if="actionPrice == 0"> RSD {{ price }}</span>
          <span v-if="actionPrice > 0" class="text-gray-400 text-xs"
            >RSD <del>{{ price }}</del></span
          >
        </p>
        <p v-if="actionPrice > 0" class="flex justify-start flex-col mb-5">
          <span class="tag">  {{ actionPrice }} </span>RSD
        </p>
        <p v-if="actionPrice > 0">ŠOK CENA!!!</p>
      </div>
    </div>
    <small class="absolute bottom-5"> *Cene su prikazane u RSD</small>
  </div>
</template>

<script>
export default {
  props: [
    "image",
    "title",
    "model",
    "price",
    "actionPrice",
    "id",
    "category",
    "index",
  ],
  methods: {
    removeProduct(i) {
      this.$store.commit("REMOVE_FROM_PROMO_LIST", i);
    },
  },
};
</script>

<style lang="scss">
.prod {
  &:hover {
    box-shadow: 0px 0px 25px -10px rgba(36, 37, 38, 0.7);
  }
}
.tag {
  @apply inline-block w-auto bg-red-500 relative text-white font-light;

  -webkit-border-radius: 3px 4px 4px 3px;
  -moz-border-radius: 3px 4px 4px 3px;
  border-radius: 3px 4px 4px 3px;
  border-left: 1px solid red;
  margin-left: 19px;
  line-height: 38px;
  padding: 0 10px;
  height: 38px;
}

/* Makes the triangle */
.tag:before {
  content: "";
  position: absolute;
  display: block;
  left: -19px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
  border-right: 19px solid rgb(239, 68, 68);
}

/* Makes the circle */
.tag:after {
  content: "";
  background-color: white;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  display: block;
  position: absolute;
  left: -9px;
  top: 17px;
}
</style>
